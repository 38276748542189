var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
      }),
      _c("line-item", [_c("span", { staticClass: "edit-bar" })]),
      _c(
        "div",
        {
          staticClass:
            "product-search__container row no-gutters align-items-center",
        },
        [
          _c(
            "div",
            { staticClass: "col-3 offset-2" },
            [
              _c("search-bar", {
                attrs: {
                  searchAction: _vm.onSearch,
                  placeholder: "Search database",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.mods.length
        ? _c("iexplorer", {
            attrs: {
              activeItems: _vm.activeItems,
              editMode: true,
              structHeader: true,
              struct: _vm.struct,
              children: _vm.children,
            },
          })
        : _vm._e(),
      _vm.addConfirmModalActive
        ? _c(
            "editing-modal",
            { attrs: { size: "sm" } },
            [
              _c(
                "alert-dialog",
                {
                  attrs: {
                    onExit: () => (_vm.addConfirmModalActive = false),
                    actions: _vm.addConfirmActions,
                    title: "Add these mods?",
                  },
                },
                [
                  _vm._l(
                    _vm.activeItems.map((el) =>
                      _vm.mods.find((mod) => mod.id === el)
                    ),
                    function (mod) {
                      return [_c("p", [_vm._v(_vm._s(mod.title))])]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }