<template>
    <div>
        <context-title
            :title="title"
            :passedActions="titleBarActions" />

        <line-item><span class="edit-bar"/></line-item>

        <div class="product-search__container row no-gutters align-items-center">
            <div class="col-3 offset-2">
                <search-bar 
                    :searchAction="onSearch" 
                    placeholder="Search database" />
            </div>
        </div>

        <iexplorer
            v-if="mods.length"
            :activeItems="activeItems"
            :editMode="true"
            :structHeader="true"
            :struct="struct"
            :children="children" />

        <editing-modal 
            v-if="addConfirmModalActive" 
            size="sm">
            <alert-dialog
                :onExit="() => addConfirmModalActive = false"
                :actions="addConfirmActions"
                title="Add these mods?">
                <template v-for="mod in activeItems.map(el => mods.find(mod => mod.id === el))">
                    <p>{{ mod.title }}</p>
                </template>
            </alert-dialog>
        </editing-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { toggleItem } from 'helpers'
import contextTitle from '../components/context_title.vue'
import lineItem from '../components/line_item.vue'
import searchBar from '../components/search_bar.vue'
import iexplorer from '../components/explorer/iexplorer.vue'
import editingModal from '../components/editing_modal.vue'
import alertDialog from '../components/alert_dialog.vue'

export default {
    name: 'StoreModAddFromDatabase',
    data() {
        return {
            title: [
                {to: {name: 'store-mod-index'}, label: 'Mods'},
                'Select mod from database',
            ],
            searchQuery: '',
            activeItems: [],
            addConfirmModalActive: false,
            addConfirmActions: [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: () => {
                        this.addConfirmModalActive = false
                    },
                },
                {
                    type: 'proceed',
                    display: 'Add',
                    run: () => {
                        this.addConfirmModalActive = false
                        this.toast('Needs API', 'error')

                        this.$router.push({name: 'store-mod-index'})
                    },
                },
            ],
            struct: {
                title: {
                    value: item => item.title,
                },
                sku: {
                    display: 'Item ID',
                    value: () => '21212',
                },
                price: {
                    display: 'Price',
                    value: () => '$2.50',
                },
                path: {
                    display: 'Path',
                    value: item => item.path,
                },
            },
        }
    },
    computed: {
        titleBarActions() {
            let baseActions = [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: () => {
                        this.$router.push({name: 'store-mod-index'})
                    },
                },
            ]

            if (this.activeItems.length) {
                baseActions.push({
                    type: 'proceed',
                    display: 'Save',
                    run: () => {
                        this.addConfirmModalActive = true
                    },
                })
            }

            return baseActions
        },
        children() {
            const ret = this.mods
                .filter(mod => {
                    if (this.query === '') {
                        return true
                    }

                    return mod.title
                        .toLowerCase()
                        .match(this.searchQuery.toLowerCase())
                })
                .map(this.assignHandlers)

            return ret
        },
        ...mapState({
            mods: state => state.mods,
        }),
    },
    methods: {
        assignHandlers(mod) {
            mod.onClick = this.handleItemClick

            return mod
        },
        handleItemClick(item) {
            this.activeItems = toggleItem(this.activeItems, item.id)
        },
        onSearch(query) {
            this.searchQuery = query
        },
    },
    components: {
        contextTitle,
        lineItem,
        searchBar,
        iexplorer,
        editingModal,
        alertDialog,
    },
}
</script>

<style lang="scss">
@import '~scss/mixins';

.product-search {
    &__container {
        height: height(12);
        background-color: #fff;
    }
}
</style>
